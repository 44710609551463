import { FlexContainer, Grid, Rectangle, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from '../../../lib/i18n';

const Summary = ({ values }) => {
  const colors = { won: '#56E475', drew: '#D9D9D9', lost: '#FF5454' };
  const percentages = {
    won: (values.won / values.played) * 100,
    drew: (values.drew / values.played) * 100,
    lost: (values.lost / values.played) * 100,
  };

  const renderStat = ({ name, value, color }) => (
    <FlexContainer flexDirection="column" alignItems="center">
      <Typography size={14} color="#666" style={{ whiteSpace: 'nowrap' }}>
        <Trans i18nKey={`teams:fixture_statistics_${name.toLowerCase()}`}>{name}</Trans>
      </Typography>
      <Typography size={24} weight={600} color="#000">
        {value}
      </Typography>
      {color && (
        <Rectangle fill={color} style={{ height: '4px', width: '100%', marginTop: '10px' }} />
      )}
    </FlexContainer>
  );
  return (
    <FlexContainer flexDirection="column" alignItems="center" style={{ gap: '16px' }}>
      <Typography size={18} color="#000" weight={600}>
        Matches Summary
      </Typography>
      <Grid columns="repeat(4, 1fr)" columnGap="30px">
        {renderStat({ name: 'Played', value: values.played })}
        {renderStat({ name: 'Won', value: values.won, color: colors.won })}
        {renderStat({ name: 'Drawn', value: values.drew, color: colors.drew })}
        {renderStat({ name: 'Lost', value: values.lost, color: colors.lost })}
      </Grid>
      <FlexContainer justifyContent="center" style={{ width: '100%' }}>
        <Rectangle
          radius="100px"
          style={{
            height: '12px',
            width: '80%',
            overflow: 'hidden',
            marginTop: '12px',
            display: 'inline-flex',
          }}
          component="div"
        >
          <div
            style={{ backgroundColor: colors.won, width: `${percentages.won}%`, height: '100%' }}
          />
          <div
            style={{ backgroundColor: colors.drew, width: `${percentages.drew}%`, height: '100%' }}
          />
          <div
            style={{ backgroundColor: colors.lost, width: `${percentages.lost}%`, height: '100%' }}
          />
        </Rectangle>
      </FlexContainer>
    </FlexContainer>
  );
};

Summary.propTypes = {
  values: PropTypes.shape({
    played: PropTypes.string,
    won: PropTypes.string,
    drew: PropTypes.string,
    lost: PropTypes.string,
  }).isRequired,
};

export default Summary;
