import ApiRequestException from '../../exceptions/api-request-exception';
import AccessDeniedException from '../../exceptions/access-denied-exception';
import PageDataParsingException from '../../exceptions/page-data-parsing-exception';
import isServer from '../is-server';
import logger from '../logger';
import PageAccessControl from './access-control';
import PageDataStore from './store';
import PageData from './data';
import MatchCentreParser from './match-centre-parser';

class PageDataLoader {
  static getPageData(key, request, forceRefreshIntervalSeconds) {
    if (isServer()) {
      return PageDataLoader.serverSideGet(key, request, forceRefreshIntervalSeconds);
    }

    return PageDataLoader.clientSideGet(key);
  }

  static async clientSideGet(key) {
    const apiUrl = `/data/club/${key.clubId}/${key.page}?${key.paramsString()}`;
    logger.info('Client side page data API request', { apiUrl });
    const response = await fetch(apiUrl, { credentials: 'include' });
    if (response.status !== 200) {
      throw new ApiRequestException('Error loading page data', response.status, apiUrl);
    }

    const apiData = await response.json();
    return new PageData(key, apiData.data, apiData.access_level);
  }

  static async serverSideGet(key, request, forceRefreshIntervalSeconds) {
    if (!request) {
      throw new Error('Server side calls must provide the request object');
    }

    const pageData = await PageDataLoader.serverSideLoad(
      key,
      request.dynamoDbClient,
      forceRefreshIntervalSeconds,
    );
    const accessControl = new PageAccessControl(key.clubId, request);
    let canAccess = false;
    try {
      canAccess = await accessControl.canUserAccessPage(pageData);
    } catch (error) {
      logger.error('Error checking access control', { error });
      throw new AccessDeniedException('Error checking access control');
    }

    if (!canAccess) {
      logger.info('Access to data denied');
      throw new AccessDeniedException('User not authorised to view private page');
    }

    if (key.page !== 'match_centre') {
      return pageData;
    }

    const userAccessLevel = await accessControl.getAccessLevel();
    logger.info('Parsing match centre data based on access', { userAccessLevel });
    const parser = new MatchCentreParser(pageData, userAccessLevel);
    try {
      return parser.parseMatchCentreData(pageData, userAccessLevel);
    } catch (error) {
      logger.error('Error Parsing match centre data', { error });
      throw new PageDataParsingException(error.message);
    }
  }

  static async serverSideLoad(key, dynamoClient, forceRefreshIntervalSeconds) {
    logger.info('Looking up page data', { key });
    try {
      const store = new PageDataStore(dynamoClient);
      const data = await store.getPageData(key, forceRefreshIntervalSeconds);
      if (data) {
        logger.info('Received page data from store', { key });
        return data;
      }
    } catch (error) {
      logger.error(`Error retrieving data from store: ${error.message}`);
    }

    logger.info('Page data not found in store', { key });
    const apiData = await PageDataLoader.loadFromApi(key);
    logger.info('Received page data from API', { key });
    return apiData;
  }

  static async loadFromApi(key) {
    const apiPath = PageDataLoader.apiPath(key);
    logger.info('Page data not found in store requesting from API');
    const { makeAPIRequest } = await import('../api-proxy');
    const response = await makeAPIRequest(apiPath);
    if (response.status !== 200) {
      throw new ApiRequestException('Error loading page data', response.status, apiPath);
    }

    const responseData = await response.json();
    const { data, access_level: accessLevel } = responseData;
    if (!data || !accessLevel) {
      throw new ApiRequestException('Malformed API response', 500, apiPath, responseData);
    }

    return new PageData(key, data, accessLevel);
  }

  static apiPath(key) {
    return `v2/club-website/${key.clubId}/page?key=${encodeURIComponent(key.stringWithoutClub())}`;
  }
}

export default PageDataLoader;
