import { Cushion, FlexContainer, Grid, MaxWidth, Rectangle } from '@pitchero/react-ui';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import HomepageTopBanner from '../adverts/homepage-top-banner';
import Shelf from '../native-adverts/shelf';
import Strip from '../native-adverts/strip';
import SeoHead from '../seo';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { FIXTURE_PROP_TYPE } from '../../lib/prop-types/fixture';
import useBreakpoint from '../../lib/use-breakpoint';
import FeaturedContentLoadingPlaceholder from './featured-content/loading';
import FeaturedContent from './featured-content';
import HomepageMatches from './matches';
import HomepageMedia from './media';
import HomepageNews from './news';
import HomepagePromotedLinks from './promoted-links';
import SectionSelector from './section-selector';
import HomepageSponsors from './sponsors';
import HomepageStats from './stats';
import HomepageTable from './tables';

const ClubHomepage = ({
  albums,
  club,
  fixtures,
  fixturesBySection,
  isLoading,
  metaDescription,
  news,
  results,
  resultsBySection,
  tablesByTeam,
  teamStatisticsBySection,
  videos,
}) => {
  const featuredNews = news[0];
  const otherNews = news.slice(1, 4);
  const isAdSupportedPackage = club.advertisingTier < 4;
  const isMobile = useBreakpoint();

  const { sponsors = [] } = club;
  const homepageSponsors = sponsors.filter((sponsor) => sponsor.placements.includes('homepage'));

  // Look up the names of the sections by id and replace their keys
  const lookupSectionNames = (bySection) =>
    Object.keys(bySection).reduce((acc, key) => {
      const section = club.sections.find((clubSection) => clubSection.id === key);
      if (section && section.name) {
        acc[section.name] = bySection[key];
      }
      return acc;
    }, {});

  // We need to sort the matches into their sections using the team ID
  const groupMatchesBySection = (matches) => {
    const grouped = {};
    matches.forEach((match) => {
      // Find which section contains the team that matches with the match
      const result = club.sections.find((section) => find(section.teams, { id: match.teamId }));

      // Add the match to the section group
      const sectionName = result.name;
      if (grouped[sectionName]) {
        grouped[sectionName] = [...grouped[sectionName], match];
      } else {
        grouped[sectionName] = [match];
      }
    });
    return grouped;
  };

  const groupTablesBySection = (tables) => {
    const grouped = {};
    tables.forEach((teamTable) => {
      // Look for teamId in sections
      const teamSection = club.sections.find((section) =>
        find(section.teams, { id: teamTable.teamId }),
      );

      if (!teamSection) {
        return;
      }
      // We want one table per section, so keep track of the team rank
      // And only save the lowest (best) ranked team's table
      const sectionName = teamSection.name;
      const teamRank = find(teamSection.teams, { id: teamTable.teamId }).rank;
      if (grouped[sectionName]) {
        // There is already a team table for this section, so if the new rank
        // is lower, replace it, otherwise discard the new table
        if (grouped[sectionName].rank > teamRank) {
          grouped[sectionName] = { rank: teamRank, ...teamTable };
        }
      } else {
        // There is no team table for this section, so save this with it's rank
        grouped[sectionName] = { rank: teamRank, ...teamTable };
      }
    });
    return grouped;
  };

  const [selectedSection, setSelectedSection] = useState(null);
  const [groupedFixtures, setGroupedFixtures] = useState({});
  const [groupedResults, setGroupedResults] = useState({});
  const [tablesBySection, setTablesBySection] = useState({});
  const [availableSections, setAvailableSections] = useState([]);
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    setGroupedFixtures(
      fixturesBySection && Object.keys(fixturesBySection).length > 0
        ? lookupSectionNames(fixturesBySection)
        : groupMatchesBySection(fixtures),
    );
    setGroupedResults(
      resultsBySection && Object.keys(resultsBySection).length > 0
        ? lookupSectionNames(resultsBySection)
        : groupMatchesBySection(results),
    );
    setTablesBySection(groupTablesBySection(tablesByTeam));
    if (teamStatisticsBySection) {
      setStatistics(lookupSectionNames(teamStatisticsBySection));
    }
  }, [fixturesBySection, resultsBySection, tablesByTeam, teamStatisticsBySection]);

  useEffect(() => {
    // Get all sections that have matches in them
    const sections = [
      ...new Set([
        ...Object.keys(groupedFixtures),
        ...Object.keys(groupedResults),
        ...Object.keys(tablesBySection),
      ]),
    ];
    // Sort the section by rank
    sections.sort((a, b) => {
      const rankA = club.sections.find((section) => section.name === a).rank;
      const rankB = club.sections.find((section) => section.name === b).rank;
      return rankA - rankB;
    });
    setAvailableSections(sections);
  }, [groupedFixtures, groupedResults, tablesBySection]);

  useEffect(() => {
    // Initialise the selected section by using the first availble section
    setSelectedSection(availableSections[0]);
  }, [availableSections]);

  return (
    <>
      <SeoHead club={club} description={metaDescription} route="homepage" title={club.siteTitle} />
      <Cushion responsive={[{ maxWidth: 'tab', props: { horizontal: '0' } }]} component="div">
        <Strip
          fill="primary"
          paddingProps={{
            vertical: 'small',
            responsive: [{ maxWidth: 'tab', props: { horizontal: 'medium' } }],
          }}
        />
        <div className="homepage__news">
          {isLoading ? (
            <FeaturedContentLoadingPlaceholder />
          ) : (
            <Rectangle fill="primary">
              <FeaturedContent club={club} message={featuredNews} />
              <HomepageNews club={club} news={otherNews} />
            </Rectangle>
          )}
          {isAdSupportedPackage ? (
            <>
              <Cushion
                top={club.sponsors.length > 0 ? '0px' : 'xlarge'}
                bottom={otherNews.length > 0 ? '0px' : 'xlarge'}
              >
                <Rectangle fill="#fff">
                  <HomepageTopBanner style={{ backgroundColor: '#fff' }} />
                </Rectangle>
              </Cushion>
              <Shelf paddingProps={{ bottom: 'medium' }} />
            </>
          ) : (
            <>
              {homepageSponsors.length > 0 && (
                <HomepageSponsors sponsors={homepageSponsors} isMobile={isMobile} />
              )}
            </>
          )}
        </div>
        {!isLoading && (
          <MaxWidth component="div" maxWidth={1366} center>
            {availableSections.length > 1 && (
              <SectionSelector
                sections={availableSections}
                selectedSection={selectedSection}
                onSectionSelected={setSelectedSection}
              />
            )}
            <Rectangle
              fill={isMobile ? '#fff' : 'transparent'}
              style={{ width: '100%' }}
              component="div"
            >
              <Cushion vertical="medium" horizontal={isMobile ? '0' : 'small'} component="div">
                <FlexContainer
                  flexDirection="column"
                  style={{ gap: '24px', width: '100%' }}
                  alignItems="center"
                >
                  <HomepageMatches
                    fixtures={groupedFixtures[selectedSection]?.slice(0, 3)}
                    results={groupedResults[selectedSection]?.slice(0, 3)}
                    club={club}
                    isMobile={isMobile}
                  />
                  <Grid
                    columns="1fr"
                    rows="auto 1fr"
                    columnGap="20px"
                    rowGap="20px"
                    alignContent="center"
                    style={{ width: '100%', maxWidth: '1200px' }}
                    responsive={[
                      {
                        minWidth: 'fullHeader',
                        props: { columns: 'repeat(auto-fit, minmax(0, 1fr))', rows: '1fr' },
                      },
                    ]}
                  >
                    <HomepageTable data={tablesBySection[selectedSection]} club={club} />
                    <HomepageStats
                      statistics={statistics[selectedSection]}
                      results={groupedResults[selectedSection]}
                      club={club}
                    />
                  </Grid>
                </FlexContainer>
              </Cushion>
            </Rectangle>
          </MaxWidth>
        )}
        {!isLoading && (
          <>
            {isMobile && <HomepagePromotedLinks club={club} />}
            {/* Render sponsors here if the upper slot was taken up by an advert */}
            {isAdSupportedPackage && homepageSponsors.length > 0 && (
              <HomepageSponsors sponsors={homepageSponsors} isMobile={isMobile} />
            )}
            <HomepageMedia albums={albums} videos={videos} club={club} />
          </>
        )}
      </Cushion>
    </>
  );
};

ClubHomepage.defaultProps = {
  albums: [],
  fixtures: [],
  fixturesBySection: {},
  news: [],
  results: [],
  resultsBySection: {},
  tablesByTeam: [],
  teamStatisticsBySection: {},
  videos: [],
};

ClubHomepage.propTypes = {
  albums: PropTypes.arrayOf(PropTypes.shape()),
  club: CLUB_PROP_TYPES.isRequired,
  fixtures: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  fixturesBySection: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.shape({})]),
  isLoading: PropTypes.bool.isRequired,
  metaDescription: PropTypes.string.isRequired,
  news: PropTypes.arrayOf(PropTypes.shape()),
  results: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  resultsBySection: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.shape({})]),
  tablesByTeam: PropTypes.arrayOf(PropTypes.shape()),
  teamStatisticsBySection: PropTypes.shape({}),
  videos: PropTypes.arrayOf(PropTypes.shape()),
};

export default ClubHomepage;
