const isServer = typeof window === 'undefined';
const isRunningTests = process.env.NODE_ENV === 'test' || process.env.INTEGRATION_TESTING;

let logger;

if (isServer) {
  const winston = require('winston'); // eslint-disable-line  global-require

  logger = winston.createLogger({
    level: 'info',
    format: winston.format.combine(winston.format.timestamp(), winston.format.json()),
  });

  if (!isRunningTests) {
    // - Write all logs error (and below) to `error.log`.
    logger.add(
      new winston.transports.File({
        name: 'file.error',
        filename: 'logs/error.log',
        level: 'error',
      }),
    );
    // - Write to all logs with level `info` and below to `combined.log`
    logger.add(
      new winston.transports.File({
        name: 'file.combined',
        filename: 'logs/combined.log',
      }),
    );
  }

  //
  // If we're not in production then log to the `console` with the format:
  // `${info.level}: ${info.message} JSON.stringify({ ...rest }) `
  //
  logger.add(
    new winston.transports.Console({
      level: isRunningTests ? 'error' : 'info',
      name: 'console',
      format: winston.format.simple(),
    }),
  );
} else {
  logger = {
    info: () => null,
    error: () => null,
  };
}

module.exports = logger;
