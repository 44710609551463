import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import ClubHomepage from '../../components/homepage';
import { getCurrentThemeDraftId } from '../../store/clubs/selectors';
import { fetchHomepageData } from '../../store/homepage/actions';
import {
  hasLoadedHomepage,
  isLoadingHomepage,
  getHomepageData,
} from '../../store/homepage/selectors';
import isServer from '../../lib/is-server';

class Homepage extends React.Component {
  static async getInitialProps({ club, reduxStore, req, res }) {
    const themeDraftId = getCurrentThemeDraftId(reduxStore.getState());
    const initialProps = {
      activeSection: 'home',
      namespacesRequired: ['common'],
      pageType: 'homepage.index',
      themeDraftId,
    };

    if (!isServer()) {
      return initialProps;
    }

    res.setHeader('x-sw-cacheable', true);

    try {
      const { error } = await reduxStore.dispatch(fetchHomepageData(club.id, themeDraftId, req));
      if (error && error.errorStatusCode && res) {
        res.statusCode = error.errorStatusCode;
      }

      return initialProps;
    } catch (err) {
      return initialProps;
    }
  }

  componentDidMount() {
    const {
      club: { id: clubId },
      isLoading,
      loadData,
      hasLoaded,
      themeDraftId,
    } = this.props;
    if (!isLoading && !hasLoaded) {
      loadData(clubId, themeDraftId);
    }
  }

  render() {
    const { club, isLoading, pageData } = this.props;
    return <ClubHomepage club={club} isLoading={isLoading} {...pageData} />;
  }
}

Homepage.defaultProps = {
  pageData: {},
  themeDraftId: null,
};

Homepage.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadData: PropTypes.func.isRequired,
  pageData: PropTypes.shape(),
  themeDraftId: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  hasLoaded: hasLoadedHomepage(state, ownProps.club.id),
  isLoading: isLoadingHomepage(state, ownProps.club.id),
  pageData: getHomepageData(state, ownProps.club.id),
});

export default connect(mapStateToProps, { loadData: fetchHomepageData })(Homepage);
