import {
  Cushion,
  FlexContainer,
  Grid,
  LinearGradient,
  MaxWidth,
  Rectangle,
  ShimmerImage,
  ShimmerTypography,
  withTheme,
} from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import useBreakpoint from '../../../lib/use-breakpoint';

const FeaturedContentLoadingPlaceholder = ({ uiTheme }) => {
  const isMobile = useBreakpoint();
  const newsGrid = (
    <Cushion top="medium" horizontal={isMobile ? 'small' : 'none'} component="div">
      <MaxWidth maxWidth={1366} center>
        <Grid
          rowGap="medium"
          responsive={[
            { minWidth: 'fullHeader', props: { columns: '1fr 1fr 1fr', columnGap: 'large' } },
          ]}
        >
          {Array(3)
            .fill(1)
            .map(() => (
              <Grid
                columns="1fr 1fr"
                columnGap="10px"
                rowGap="10px"
                responsive={[
                  { minWidth: 'fullHeader', props: { columns: '1fr', rows: '1fr 1fr' } },
                ]}
              >
                <ShimmerImage fullWidth />
                <FlexContainer flexDirection="column" style={{ gap: '10px' }}>
                  <ShimmerTypography preset="title--large" width="100%" />
                  <ShimmerTypography preset="title--small" width="66%" />
                </FlexContainer>
              </Grid>
            ))}
        </Grid>
      </MaxWidth>
    </Cushion>
  );

  return (
    <>
      <Rectangle fill="primary" component="div">
        <MaxWidth component="div" maxWidth={1366} center>
          <Cushion bottom="medium" component="div">
            <Grid
              columns="1fr"
              rows="1fr 1fr"
              rowGap="20px"
              columnGap="20px"
              responsive={[{ minWidth: 'fullHeader', props: { columns: '5fr 4fr', rows: '1fr' } }]}
              style={{ backgroundColor: 'rgba(50, 50, 50, 0.5)' }}
            >
              <ShimmerImage fullWidth />
              <Cushion left="small">
                <FlexContainer
                  flexDirection="column"
                  style={{ gap: '10px' }}
                  justifyContent="start"
                  responsive={[{ minWidth: 'fullHeader', props: { justifyContent: 'center' } }]}
                >
                  <ShimmerTypography preset="title--large" width="75%" />
                  <ShimmerTypography preset="title--small" width="90%" />
                  <ShimmerTypography preset="title--small" width="90%" />
                  <ShimmerTypography preset="title--small" width="20%" />
                </FlexContainer>
              </Cushion>
            </Grid>
          </Cushion>
        </MaxWidth>
      </Rectangle>
      {isMobile ? (
        <Rectangle fill="white" component="div">
          {newsGrid}
        </Rectangle>
      ) : (
        <LinearGradient
          component="div"
          angle={180}
          stops={[
            [0, uiTheme.colors.primary],
            ['35%', uiTheme.colors.primary],
            ['35%', '#f1f1f1'],
          ]}
        >
          {newsGrid}
        </LinearGradient>
      )}
    </>
  );
};

FeaturedContentLoadingPlaceholder.propTypes = {
  uiTheme: PropTypes.shape({ colors: PropTypes.shape({ primary: PropTypes.string }) }).isRequired,
};

export default withTheme(FeaturedContentLoadingPlaceholder);
