import React from 'react';
import PropTypes from 'prop-types';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import clubUrl from '../../lib/routing/club-url';

const CanonicalLink = ({ club, route, params }) => {
  try {
    const url = clubUrl(club, route, params);
    return <link rel="canonical" href={url} />;
  } catch (error) {
    // Do not allow an error generating the canonical URL prevent the page from loading.
    return null;
  }
};

CanonicalLink.defaultProps = {
  params: {},
};

CanonicalLink.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  route: PropTypes.string.isRequired,
  params: PropTypes.shape(),
};

export default CanonicalLink;
