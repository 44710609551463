import { Cushion, FlexContainer, Rectangle, Typography } from '@pitchero/react-ui';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ClubLink from '../../club-link';
import { Trans } from '../../../lib/i18n';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import { FIXTURE_PROP_TYPE } from '../../../lib/prop-types/fixture';
import useBreakpoint from '../../../lib/use-breakpoint';
import { isAuthorised } from '../../../store/membership/selectors';
import { getClubTeams, getSeason, getDefaultSeasonId } from '../../../store/clubs/selectors';
import LoginButton from '../../auth/login-button';
import MoreLink from '../more-link';
import FormGuide from './form-guide';
import Summary from './summary';

const MobileStatWrapper = styled.div`
  box-sizing: border-box;
  margin: 24px 24px 6px;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  max-width: calc(100vw - 48px); // Screen width - margin left & right
`;

const HomepageStats = ({ results, statistics, club, authorised, teams, season }) => {
  if (isEmpty(results) && isEmpty(statistics)) return null;
  const isMobile = useBreakpoint();
  const [accessDenied, setAccessDenied] = useState(false);
  const [values, setValues] = useState({});
  const [ppg, setPpg] = useState('0');
  const [form, setForm] = useState([]);
  const [teamId, setTeamId] = useState('');
  const [seasonStart, setSeasonStart] = useState(null);
  const [seasonEnd, setSeasonEnd] = useState(null);

  useEffect(() => {
    let latestSeason = null;
    let latestDate = null;
    club.seasons.forEach((clubSeason) => {
      const date = DateTime.fromISO(clubSeason.start);
      if (!latestDate || date > latestDate) {
        latestDate = date;
        latestSeason = clubSeason;
      }
    });
    setSeasonStart(DateTime.fromISO(latestSeason?.start));
    setSeasonEnd(DateTime.fromISO(latestSeason?.end));
  }, []); // Only run on first render

  useEffect(() => {
    const statsTeamId = Object.keys(statistics)?.[0];
    if (statsTeamId !== teamId) {
      setTeamId(statsTeamId);
    }
  }, [statistics]);

  useEffect(() => {
    const stats = statistics[teamId];
    const accessLevel = stats?.requiredAccessLevel;

    // Only compute stats if access level is public, or user is authorised
    if (accessLevel === 'public' || (accessLevel === 'private' && authorised)) {
      setAccessDenied(false);
      setValues({
        played: `${stats?.fixtureStatistics.played}`,
        won: `${stats?.fixtureStatistics.W}`,
        drew: `${stats?.fixtureStatistics.D}`,
        lost: `${stats?.fixtureStatistics.L}`,
      });
      setPpg(`${stats?.fixtureStatisticsBreakdown?.average_points}` || '0');

      const filteredResults = results
        .map((result) => {
          // Check result is for current team, and in current season
          const date = DateTime.fromISO(result.dateTime);
          if (result.teamId === parseInt(teamId, 10) && date >= seasonStart && date <= seasonEnd) {
            return result.outcome;
          }
          return null;
        })
        .filter(Boolean);
      setForm(filteredResults);
    } else {
      setAccessDenied(true);
    }
  }, [teamId, authorised]);

  const getTitleText = () => {
    if (teamId && season?.name) {
      const team = teams.find((t) => t.id.toString() === teamId);
      return `${team?.name} - ${season.name.trim()}`;
    }
    return 'Stats';
  };
  const title = (
    <Rectangle fill="#000" component="div">
      <Cushion vertical="small">
        <Typography
          color="white"
          preset="title"
          align="center"
          size={isMobile ? 14 : 16}
          component="div"
        >
          {getTitleText()}
        </Typography>
      </Cushion>
    </Rectangle>
  );

  const moreLink = teamId ? (
    <div style={{ visibility: accessDenied ? 'hidden' : 'visible' }}>
      <MoreLink club={club} route="team.statistics" params={{ teamId }}>
        <Trans i18nKey="common:view_all_stats">More Stats</Trans>
      </MoreLink>
    </div>
  ) : null;

  const wrapWithLink = (content) => {
    if (!teamId || accessDenied) return content;
    return (
      <ClubLink route="team.statistics" params={{ teamId }}>
        <a>{content}</a>
      </ClubLink>
    );
  };

  const loginCta = (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        width: '100%',
        height: '100%',
        gap: '20px',
        paddingTop: isMobile ? '40px' : '20px',
        paddingBottom: isMobile ? 0 : '40px',
      }}
    >
      <Typography color="black" preset="description" size={18}>
        <Trans i18nKey="common:homepage_stats_access_denied">Login to view stats</Trans>
      </Typography>
      <LoginButton
        translationKey="common:login"
        style={{ borderRadius: '10px', boxShadow: 'none' }}
      />
    </FlexContainer>
  );

  if (isMobile) {
    return (
      <div>
        {wrapWithLink(
          <FlexContainer
            flexDirection="column"
            style={{ width: '100%', backgroundColor: '#fff' }}
            component="div"
          >
            {title}
            {accessDenied ? (
              loginCta
            ) : (
              <>
                {values.won && (
                  <MobileStatWrapper>
                    <Summary values={values} />
                  </MobileStatWrapper>
                )}
                {form.length > 0 && (
                  <MobileStatWrapper>
                    <FormGuide data={{ form, ppg }} />
                  </MobileStatWrapper>
                )}
              </>
            )}
          </FlexContainer>,
        )}
        {!accessDenied && (
          <Cushion horizontal="medium" top="small" component="div">
            {moreLink}
          </Cushion>
        )}
      </div>
    );
  }

  return (
    <FlexContainer flexDirection="column" component="div">
      <Rectangle
        radius="8px"
        style={{ overflow: 'hidden', flexGrow: 1, backgroundColor: '#fff' }}
        component="div"
      >
        {wrapWithLink(
          <FlexContainer
            flexDirection="column"
            style={{ height: '100%', gap: '25px' }}
            component="div"
          >
            {title}
            {accessDenied ? (
              loginCta
            ) : (
              <>
                {values.won && <Summary values={values} />}
                {form.length > 0 && <FormGuide data={{ form, ppg }} />}
              </>
            )}
          </FlexContainer>,
        )}
      </Rectangle>
      {moreLink}
    </FlexContainer>
  );
};

HomepageStats.propTypes = {
  results: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  statistics: PropTypes.shape({}),
  club: CLUB_PROP_TYPES.isRequired,
  authorised: PropTypes.bool.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  season: PropTypes.shape().isRequired,
};

HomepageStats.defaultProps = {
  results: [],
  statistics: {},
};

const mapStateToProps = (state, ownProps) => ({
  authorised: isAuthorised(state, ownProps.club.id),
  teams: getClubTeams(state, ownProps.club.id),
  season: getSeason(state, getDefaultSeasonId(state)),
});

export default connect(mapStateToProps)(HomepageStats);
