import { Cushion, Grid, LinearGradient, MaxWidth, Rectangle, withTheme } from '@pitchero/react-ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from '../../../lib/i18n';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import useBreakpoint from '../../../lib/use-breakpoint';
import ActivityStreamMessage from '../../activity-stream/message';
import MoreLink from '../more-link';

const HomepageNews = ({ club, news, uiTheme }) => {
  const isMobile = useBreakpoint();
  const content = (
    <Cushion vertical="medium" horizontal="small">
      <MaxWidth maxWidth={1366} center>
        <Grid
          rowGap="medium"
          responsive={[
            { minWidth: 'fullHeader', props: { columns: '1fr 1fr 1fr', columnGap: 'large' } },
          ]}
        >
          {news.map((item) => (
            <ActivityStreamMessage
              key={item.id}
              message={item}
              stacked="fullHeader"
              isV2
              imageProps={{
                alignment: 'top',
                width: '600',
                height: '336',
                loading: 'lazy',
              }} // 16:9 ratio
            />
          ))}
        </Grid>
        <MoreLink club={club} route="news">
          <Trans i18nKey="news:more">More News</Trans>
        </MoreLink>
      </MaxWidth>
    </Cushion>
  );

  if (isMobile) {
    return <Rectangle fill="white">{content}</Rectangle>;
  }

  const primary = get(uiTheme, 'colors.primary');
  return (
    <>
      <LinearGradient
        component="div"
        angle={180}
        stops={[
          [0, primary],
          ['35%', primary],
          ['35%', '#f1f1f1'],
        ]}
      >
        {content}
      </LinearGradient>
    </>
  );
};

HomepageNews.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  news: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  uiTheme: PropTypes.shape({}).isRequired,
};

export default withTheme(HomepageNews);
