import { map, filter, pickBy, identity } from 'lodash';

class PageKey {
  constructor(clubId, page, params = {}) {
    this.clubId = clubId;
    this.page = page;
    this.params = params;
  }

  paramsString() {
    return map(pickBy(this.params, identity), (value, key) => `${key}=${value}`).join('&');
  }

  stringWithoutClub() {
    const parts = filter([this.page, this.paramsString()]);

    return parts.join(':');
  }

  toString() {
    const parts = filter([`club${this.clubId}`, this.page, this.paramsString()]);

    return parts.join(':');
  }
}

export default PageKey;
