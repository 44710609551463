import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Cushion } from '@pitchero/react-ui';

function getDivIdForType(type) {
  switch (type) {
    case 'strip':
      return 'js-ab-strip';
    case 'shelf':
      return 'js-ab-shelf';
    default:
      return '';
  }
}

const Advert = ({ paddingProps, type }) => {
  const [served, setServed] = useState(false);

  const handleServed = useCallback(() => {
    setServed(get(window, `adbutlerServed.${type}`, false));
  }, []);

  useEffect(() => {
    document.addEventListener(`adbutlerServed.${type}`, handleServed);

    return () => {
      document.removeEventListener(`adbutlerServed.${type}`, handleServed);
    };
  }, [handleServed]);

  const padding = served ? paddingProps : {};

  return (
    <Cushion component="div" {...padding}>
      <div id={getDivIdForType(type)} />
    </Cushion>
  );
};

Advert.propTypes = {
  paddingProps: PropTypes.shape({}),
  type: PropTypes.oneOf(['strip', 'shelf']).isRequired,
};
Advert.defaultProps = {
  paddingProps: {},
};
export default Advert;
