import { get } from 'lodash';
import fs from 'fs';
import { DateTime } from 'luxon';
import PageData from './data';

const logger = require('../logger');

class PageDataStore {
  constructor(dynamoDbClient) {
    this.dynamoDbClient = dynamoDbClient;
  }

  getPageData(key, forceRefreshIntervalSeconds) {
    if (process.env.INTEGRATION_TESTING) {
      const fixturesDirectory = 'cypress/cypress/fixtures';

      return new Promise((resolve) => {
        try {
          const rawData = fs.readFileSync(
            `${fixturesDirectory}/page-data/${key.clubId}/${key.stringWithoutClub()}.json`,
          );
          const parsedData = JSON.parse(rawData);
          const { key: jsonKey, data, access_level: accessLevel } = parsedData;
          logger.info('Loaded page data from file');
          resolve(new PageData(jsonKey, data, accessLevel));
        } catch (error) {
          logger.error('Error loading page data from file', error);
          resolve(null);
        }
      });
    }

    return new Promise((resolve) => {
      const params = {
        TableName: PageDataStore.tableName(),
        Key: {
          page_key: key.toString(),
        },
      };

      logger.info('Checking DynamoDB for page data', { params });
      this.dynamoDbClient.get(params, (err, dynamoData) => {
        if (err) {
          if (err.code !== 'ResourceNotFoundException') {
            logger.error('DynamoDb get Error', err);
          }

          resolve(null);
        }

        // Optional check to make sure our dynamoDB data is up to date,
        // if a forceRefreshIntervalSeconds is passed we will only return the DynamoData
        // if it was generated after our required time.
        const generatedAt = get(dynamoData, 'Item.generated', null);
        if (!!generatedAt && !!forceRefreshIntervalSeconds) {
          try {
            const generatedAtTime = DateTime.fromISO(generatedAt);
            const now = DateTime.local();
            const timeToCheck = now.minus({ seconds: forceRefreshIntervalSeconds });
            if (generatedAtTime < timeToCheck) {
              logger.info('DynamoDb data was too old. Should fetch from the API instead');
              // if generatedAtTime is before timeToCheck we do not want the data
              resolve(null);
            }
          } catch (error) {
            logger.error('Error checking data is new enough', error);
            resolve(null);
          }
        }

        const data = get(dynamoData, 'Item.data', null);
        const accessLevel = get(dynamoData, 'Item.accessLevel', null);

        if (data && accessLevel) {
          resolve(new PageData(key, data, accessLevel));
        }

        resolve(null);
      });
    });
  }

  static tableName() {
    return `page_data_${PageDataStore.standardizedEnvironment()}`;
  }

  static standardizedEnvironment() {
    const environment = process.env.NODE_ENV;
    if (environment === 'production') {
      return 'production';
    }

    return 'local';
  }
}

export default PageDataStore;
