import React from 'react';
import PropTypes from 'prop-types';
import { MaxWidth, Rectangle } from '@pitchero/react-ui';
import Advert from './advert';

/**
 * Native ad placement "Strip"
 * Content controlled in AdButler via Google Tag Manager
 * This placement should collapse fully when not in use
 *
 * @returns {*}
 * @constructor
 */
const Strip = ({ fill, paddingProps }) => (
  // "ab" stands for "AdButler"
  <Rectangle fill={fill} component="div">
    <MaxWidth component="div" maxWidth={1320} center>
      <Advert type="strip" paddingProps={paddingProps} />
    </MaxWidth>
  </Rectangle>
);
Strip.propTypes = {
  fill: PropTypes.string,
  paddingProps: PropTypes.shape({}),
};
Strip.defaultProps = {
  fill: undefined,
  paddingProps: {},
};
export default Strip;
