import { Cushion, FlexContainer, Rectangle, Typography, withTheme } from '@pitchero/react-ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useBreakpoint from '../../lib/use-breakpoint';
import { Trans } from '../../lib/i18n';

const MobileSectionButton = styled.button`
  border-radius: 100px;
  padding: 7px 12px;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  ${(props) => `
    background-color: ${props.bgColor};
    color: ${props.textColor}
  `}
`;

const DesktopSectionButton = styled.button`
  border-radius: 100px;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  ${(props) => `
    background-color: ${props.bgColor};
    color: ${props.textColor};
  `}
`;

const SectionSelector = ({ sections, selectedSection, onSectionSelected, uiTheme }) => {
  const bgColor = get(uiTheme, 'colors.primary', '#f0f0f0');
  const textColor = get(uiTheme, 'colors.primaryContrast', '#fff');
  const isMobile = useBreakpoint();

  if (isMobile) {
    return (
      <Rectangle fill="#fff">
        <Cushion left="small" top="24px">
          <Typography preset="title" color="#000">
            <Trans i18nKey="common:homepage_subtitle_matches">Matches</Trans>
          </Typography>
          <Cushion vertical="small">
            <FlexContainer style={{ gap: '5px', overflow: 'scroll' }}>
              {sections.map((section) => (
                <MobileSectionButton
                  key={section}
                  bgColor={selectedSection === section ? bgColor : '#f0f0f0'}
                  textColor={selectedSection === section ? textColor : 'black'}
                  onClick={() => onSectionSelected(section)}
                >
                  {section}
                </MobileSectionButton>
              ))}
            </FlexContainer>
          </Cushion>
        </Cushion>
      </Rectangle>
    );
  }
  return (
    <FlexContainer justifyContent="center" style={{ width: '100%', marginTop: '24px' }}>
      <Rectangle radius="100px" fill="#fff">
        <FlexContainer style={{ gap: '8px', padding: '8px' }}>
          {sections.map((section) => (
            <DesktopSectionButton
              key={section}
              bgColor={selectedSection === section ? bgColor : '#fff'}
              textColor={selectedSection === section ? textColor : '#000'}
              onClick={() => onSectionSelected(section)}
            >
              {section}
            </DesktopSectionButton>
          ))}
        </FlexContainer>
      </Rectangle>
    </FlexContainer>
  );
};

SectionSelector.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSection: PropTypes.string,
  onSectionSelected: PropTypes.func.isRequired,
  uiTheme: PropTypes.shape(),
};

SectionSelector.defaultProps = {
  uiTheme: {},
  selectedSection: null,
};

export default withTheme(SectionSelector);
